* {
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
}

:root {
  --primary: #37495F;
  --dark-gray: #CCCCCC;
  --light-green: #34D1BA;
  --dark-gray-500: #D9D9D9;
  --light-white: #FCFCFF;
  --dark-gray-light: #F5F5F5;
  --light-gray: #DEDEDE;
  --periwinkle: #E4E2FF;
  --dark-slate-gray: #303D45;
  --dark-text: "#3B3C3E";
  --icon: #00668C;
  --gray-text: #9F9F9F;
  --light-blue: #e4ecf1;
  --blue-bell: #5A54A3;
  --gray: #979797;
  --goldenrod: #FEB749;
  --coral: #FF7475;
  --medium-sea-green: #50CB74;
}

.container {
  margin: auto;
  padding: 10px 0;
}

body {
  direction: rtl;
  font-family: 'Almarai', sans-serif;
  font-family: 'Cairo', sans-serif;
  font-family: 'Chivo', sans-serif;
  font-family: 'Hind', sans-serif;
  font-family: 'Italianno', cursive;
  font-family: 'Lato', sans-serif;
  font-family: 'Oswald', sans-serif;
  font-family: 'Poppins', sans-serif;
  font-family: 'Readex Pro', sans-serif;
}

/* 
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------       Gloable Classes        ----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#

*/

svg,
i {
  cursor: pointer;
}

.input:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #C7D2FE;
  border-color: #6366F1;
}

.transition {
  transition: 0.5s !important;
}

.position-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.hide-input {
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  appearance: none;
  display: none;
}

.loader {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid var(--primary);
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.num-container {
  width: 54px;
  height: 60px;
  background-color: #CBE1EE;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 3px;
  border-radius: 10px 10px 20px 20px;
  z-index: 111;
  border: 1px solid #71C4F0;
}

.num-container h1 {
  font-size: 20px;
}

.num-container p {
  font-size: 7px;
}

.circle::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #5A54A2;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.active-section-title {
  position: relative;
}

.active-section-title::after {
  content: "";
  width: 4px;
  height: 100%;
  position: absolute;
  right: -20px;
  top: 0;
  background-color: var(--blue-bell);
  border-radius: 30px;
}


/* 
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------     Primereact Classes       ----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#

*/



.p-dropdown .p-dropdown-label.p-placeholder {
  color: var(--dark-gray-500) !important;
}

.p-input-icon-right>.p-inputtext {
  width: 100% !important;
}

.p-input-icon-left>i,
.p-input-icon-right>i,
.p-input-icon-left>svg,
.p-input-icon-right>svg,
.p-input-icon-left>.p-input-prefix,
.p-input-icon-right>.p-input-suffix {
  margin-top: 0 !important;
  transform: translateY(-50%);
  right: initial !important;
  left: 15px !important;
  cursor: pointer;
}

.p-input-icon-right>.p-inputtext {
  padding-right: 15px !important;
}

.p-calendar-w-btn-right .p-datepicker-trigger {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.p-calendar-w-btn-right .p-inputtext {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.p-dataview .p-paginator-bottom {
  direction: ltr;
}

.p-inline-message.p-inline-message-error {
  margin: 10px 0;
  width: 100%;
}

.grid-nogutter {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.p-dataview-emptymessage {
  grid-column: span 12 / span 12 !important;
}

.p-datatable .p-paginator-bottom {
  direction: ltr;
}

.p-datatable .p-datatable-thead>tr>th {
  background-color: transparent !important;
  border: none !important;
  margin-bottom: 20px !important;
  padding: 20px 1rem !important;
}

.p-datatable-table thead {
  border-radius: 5px;
  background: #FFF;
}

.p-datatable .p-datatable-tbody>tr {
  border: 1px solid #F3F3F3;
  border-radius: 10px;
}

.p-datatable>.p-datatable-wrapper {
  overflow: initial !important;
}

.p-datatable .p-datatable-header {
  background-color: transparent !important;
  border: none !important;
}

.p-datatable .p-datatable-tbody>tr:hover {
  transition: 0.8s ease all;
  scale: 1.03 !important;
  box-shadow: 0px 0px 6px 0px #EFEFFF;
  position: relative;
  z-index: 1000;
}

.p-datatable .p-sortable-column .p-column-title,
.p-datatable .p-sortable-column .p-sortable-column-icon,
.p-datatable .p-sortable-column .p-sortable-column-badge {
  margin-right: 10px !important;
}

.p-datatable .p-sortable-column .p-column-title,
.p-datatable .p-sortable-column .p-sortable-column-icon,
.p-datatable .p-sortable-column .p-sortable-column-badge {
  font-size: 13px !important;
}

.origins-info .p-dataview .p-dataview-content,
.origins-info .p-paginator {
  background-color: transparent !important;
}

.login .p-inputtext {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.active-section {
  background-color: var(--blue-bell);
  height: 70%;
  width: 5px;
  border-radius: 5px 0px 0px 5px;
  padding: 10px 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  display: none !important;
}


.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  background-color: #34d1ba68 !important;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  width: 30px !important;
  background-color: var(--light-green) !important;
  border-radius: 10px !important;
}


.p-datatable .p-datatable-tbody>tr>td {
  text-align: right;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  flex-direction: row-reverse !important;
  justify-content: space-between !important;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  margin-right: 0rem !important;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  right: initial !important;
  left: 0.75rem !important;
}

/* 
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------         Animations           ----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#

*/


@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}